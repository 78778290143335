
  import { MyWorkActivityRowColumn } from "@/components/my-work/activity-row.vue";
  import { Component, Prop, Vue } from "vue-property-decorator";

  export type MyWorkActivityGroupType = "due" | "today" | "unscheduled" | "next_7_days" | "next_14_days";

  export interface MyWorkActivityGroupData {
    title: string;
    open: boolean;
    type: MyWorkActivityGroupType;
    color?: string;
    activities?: MyWorkActivity[];
    clickHandler: (activityId: number, type: MyWorkActivityGroupType) => void;
  }

  @Component({
    components: {
      GeneralAccordion: () => import("@/components/general/accordion.vue"),
      MyWorkActivityRow: () => import("@/components/my-work/activity-row.vue"),
      MyWorkTaskGroup: () => import("@/components/my-work/task-group.vue"),
    },
  })
  export default class MyWorkActivityGroup extends Vue {
    @Prop() group!: MyWorkActivityGroupData;
    @Prop() user!: { first_name: string; last_name: string; media: Media | null; preview_url: string | null };
    @Prop({ required: true }) readonly myWorkActivityList!: MyWorkActivityList;
    @Prop({ required: true }) readonly myWorkTaskList!: MyWorkTaskList;
    @Prop({ required: true }) readonly getActivityLabel!: (activityId: number, type: MyWorkActivityGroupType) => MyWorkActivityActionLabel | undefined;
    @Prop({ required: true }) readonly loadingTasksForActivities!: {
      id: number;
      due: MyWorkActivityGroupType;
    }[];
    @Prop({ required: true }) readonly openedMyWorkActivityRows!: {
      id: number;
      due: MyWorkActivityGroupType;
    }[];
    @Prop({ required: true }) readonly columns!: MyWorkActivityRowColumn[];
    @Prop({ required: true }) readonly checkedTasks!: number[];
    @Prop({ required: true }) readonly hoveringTasks!: number[];
    @Prop({ required: true }) readonly hoveringType!: string | null;
    @Prop({ required: true }) readonly bulkUpdating!: boolean;

    /**
     * @name Verify if activity group is open
     * @description Check if the tasks is already loaded for the activity group, and if the activity group is already opened.
     *
     * @param {number} activityId - The activityId
     * @param {MyWorkActivityGroupType} type - The activity group type
     * @returns boolean
     */
    isActivityGroupOpen(activityId: number, type: MyWorkActivityGroupType) {
      if (this.loadingTasksForActivities.find((t) => t.id === activityId && t.due === type)) {
        return false;
      }

      return this.openedMyWorkActivityRows.find((t) => t.id === activityId && t.due === type);
    }

    get sortedActivitiesWithActivityLabels() {
      return this.group.activities
        ? this.group.activities
            .map((activity) => {
              return {
                activity,
                label: this.getActivityLabel(activity.id, this.group.type),
              };
            })
            .sort((a, b) => {
              if (a.label && b.label) {
                return new Date(a.label.deadline).getTime() - new Date(b.label.deadline).getTime();
              }
              return 0;
            })
        : [];
    }
  }
